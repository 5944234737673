// @ts-nocheck
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button, Form, Modal } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

interface ModelProps {
  visible: boolean;
  toggle: any;
}

export const AddCardModal = (props: ModelProps) => {
  const { visible, toggle } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    if (!card) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });
    setErrorMessage(error?.message ?? null);
    if (paymentMethod) {
      await axios
        .post('/v1/user/payment-method', paymentMethod)
        .then(({ data }) => {
          setErrorMessage(data.error ?? null);
          if (!data.error) {
            props.toggle();
          }
        });
    }
  };

  return (
    <Modal
      title="Add Card"
      visible={visible}
      onOk={() => toggle()}
      onCancel={() => toggle()}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <CardElement />
        {/* <p>Input Card Details Below</p> */}
        {/*<Label>Card Number</Label>*/}
        {/*<CardNumberElement />*/}
        {/*<Label>Expiration Date</Label>*/}
        {/*<CardExpiryElement />*/}
        {/*<Label>CVC (On Reverse)</Label>*/}
        {/*<CardCvcElement />*/}
        {errorMessage ? <Alert type="error" message={errorMessage} /> : null}
        <div style={{"display": "flex", marginTop: '10px', justifyContent: "flex-end"}}>
          <Button
            type="default"
            onClick={() => toggle()}
            style={{ float: 'left', marginRight: "10px" }}
          >
            Cancel
          </Button>
      
          <Button type="primary" htmlType="submit" style={{ float: 'right'}}>
            Add Card
          </Button>
        </div>
        
      </Form>
    </Modal>
  );
};
