export const SET_REGISTER_USER = 'SET_REGISTER_USER';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';
export const SET_LICENSE_INFO = 'SET_LICENSE_INFO';
export const SET_CARD_INFO = 'SET_CARD_INFO';
export const SET_USER = 'SET_USER';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const UPDATE_REGISTRATION_SESSION = 'UPDATE_REGISTRATION_SESSION';
export const GET_STRIPE_KEY = 'GET STRIPE KEY';
export const CHECK_LICENSE = 'CHECK LICENSE';
export const TOKEN = 'TOKEN';
export const LOGOUT_ACTION = 'LOGOUT ACTION';
