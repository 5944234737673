// @ts-nocheck
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Subscription } from 'src/store/subscriptions/interfaces';
import {
  addSubscriptions,
  changeSubscriptions,
  deleteSubscriptions,
} from 'src/store/subscriptions/thunks';

interface Props {
  subscription: Subscription;
  visible: boolean;
  toggle: any;
}

export const SubscriptionAmendModal = (props: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { subscription, visible, toggle } = props;

  useEffect(() => {
    form.setFieldsValue(subscription);
  }, [form, subscription]);

  const handleSubmit = (formData: any) => {
    if (subscription.id) {
      dispatch(changeSubscriptions(subscription.id, formData));
      toggle();
    } else {
      dispatch(addSubscriptions(formData));
      toggle();
    }
  };

  const deleteSubscription = () => {
    dispatch(deleteSubscriptions(subscription.id));
    toggle();
  };

  return (
    <Modal
      visible={visible}
      onOk={() => toggle()}
      onCancel={() => toggle()}
      footer={[
        subscription.id ? (
          <Button
            type="primary"
            danger
            htmlType="button"
            onClick={deleteSubscription}
            style={{ float: 'left' }}
          >
            Delete
          </Button>
        ) : (
          <div />
        ),
        <Button htmlType="button" onClick={() => toggle()}>
          Close
        </Button>,
        <Button form="subscription-form" type="primary" htmlType="submit">
          Save
        </Button>,
      ]}
    >
      <Card
        title={subscription.id ? 'Edit Subscription' : 'Create Subscription'}
      >
        <Form
          id="subscription-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="description" label="Description">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="extra" label="Extra Desc.">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="qty_available" label="Qty. Available">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="cost" label="Cost">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};
