import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from './store';

import { verifyError, verifySuccess } from './user/actions';

export const updateUserProperties = (
  username: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {};

export const attemptVerifyEmail = (
  token: string
): ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
  const url = '/api/v1/auth/verify-email';
  fetch(url, { method: 'POST', body: JSON.stringify({ token }) })
    .then((res) => res.json())
    .then(() => dispatch(verifySuccess(token)))
    .catch((e) => dispatch(verifyError()));
};
