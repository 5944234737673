import { message } from 'antd';
import * as interfaces from './interfaces';
import * as types from './types';

const initialState: interfaces.ShowMessageReducer = {
  variant: '',
  message: '',
  autoHide: 5000,
  isShow: false,
};

const showMessageReducer = (
  state = initialState,
  action: interfaces.ShowMessageActions
): interfaces.ShowMessageReducer => {
  switch (action.type) {
    case types.SHOW_MESSAGE: {
      if (action.payload.variant === 'danger') {
        message.error(action.payload.message);
      } else {
        message.success(action.payload.message);
      }
      return {
        variant: action.payload.variant,
        message: action.payload.message,
        autoHide: 5000,
        isShow: true,
      };
    }
    case types.HIDE_MESSAGE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default showMessageReducer;
