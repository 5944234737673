// @ts-nocheck
import { Alert, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { AppState } from 'src/store/store';
import { attemptVerifyEmail } from 'src/store/thunks';

export const VerifyEmailPage = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const verifySuccess = useSelector(
    (state: AppState) => state.verifyEmail.success
  );
  const verifyError = useSelector((state: AppState) => state.verifyEmail.error);
  const authData = useSelector((state: AppState) => state.user.token);

  useEffect(() => {
    if (!authData && token) {
      dispatch(attemptVerifyEmail(token));
    }
    history.replace(history.location.pathname);
  }, [authData, dispatch, history, token]);

  useEffect(() => {}, [history, verifySuccess]);

  if (verifySuccess) {
    return <Redirect to="/set-password" />;
  }

  return verifyError ? (
    <>
      <Alert
        type="error"
        message="Unable to verify email.  Token may have expired."
      />
    </>
  ) : (
    <Spin />
  );
};
