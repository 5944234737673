// @ts-nocheck
import { Alert, Button, Descriptions, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CodeHistory } from 'src/store/codes/interfaces';
import { getUIConfig } from 'src/store/uiconfig/thunks';
import { AppState } from 'src/store/store';
import { IMakeQuery } from 'src/store/uiconfig/interfaces';

interface Props {
  visible: boolean;
  toggle: Function;
  codeInfo: CodeHistory | null;
}

export const CodeHistoryModal = (props: Props) => {
  const { toggle, visible, codeInfo } = props;
  const uiConfig = useSelector((state: AppState) => state.uiConfigReducer.codeRequestConfigs);

  const dispatch = useDispatch()
  dispatch(getUIConfig());

  if (!codeInfo) {
    return null;
  }

  let query: IMakeQuery | undefined;
  const makeLookupKey = (codeInfo.make || '').toLowerCase();
  let makeConfig = uiConfig.find((mc) => {
    return mc.id === makeLookupKey;
  });
  if (makeConfig) {
    query = makeConfig.queries.find((q) => {
      return q.type === codeInfo.query_type;
    });
  }

  const responseKVs = Object.keys(codeInfo.response).map((k) => {
    return { key: k, value: codeInfo.response[k] };
  });

  return (
    <Modal
      title="Code Details"
      visible={visible}
      onOk={() => toggle()}
      onCancel={() => toggle()}
      footer={[
        <Button type="primary" onClick={() => toggle()}>
          OK
        </Button>,
      ]}
    >
      <Descriptions size="small" bordered column={1}>
        <Descriptions.Item label="Make">{codeInfo.make}</Descriptions.Item>
        <Descriptions.Item label="Model">{codeInfo.model}</Descriptions.Item>
        <Descriptions.Item label="Year">{codeInfo.year}</Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions size="small" bordered column={1}>
        <Descriptions.Item label="Query Type">{query?.name || codeInfo.query_type}</Descriptions.Item>
        <Descriptions.Item label="Input VIN">{codeInfo.vin}</Descriptions.Item>
        {query?.data0 && <Descriptions.Item label={query.data0.name}>{codeInfo.data0}</Descriptions.Item>}
        {query?.data1 && <Descriptions.Item label={query.data1.name}>{codeInfo.data1}</Descriptions.Item>}
      </Descriptions>

      {responseKVs.length > 0 && (
        <>
          <Divider />
          <Descriptions size="small" bordered column={1}>
            {responseKVs.map((kv) => (
              <Descriptions.Item key={kv.key} label={kv.key}>{kv.value}</Descriptions.Item>
            ))}
          </Descriptions>
        </>
      )}

      {!!codeInfo.error && (
        <>
          <Divider />
          <Alert type="error" message={codeInfo.error} />
        </>
      )}
    </Modal>
  );
};
