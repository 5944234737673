import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { showMessageAction } from '../showMessage/actions';
import { AppState } from '../store';
import { getUserSubscriptionsAction } from '../subscriptions/actions';
import {
  getPersonalInfoAction,
  getProfileInfoAction,
  getUserPaymentsHistoryAction,
  getUserTransactionHistoryAction,
  updateUserPersonalInfoAction,
  updateUserProfileInfoAction,
} from './actions';
import { PersonalInfo, ProfileInfo } from './interfaces';

export const getProfileInfo = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('/v1/user/profile');
    dispatch(getProfileInfoAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
};

export const getPersonalInfo = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('/v1/user/personal-info');
    dispatch(getPersonalInfoAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
};

export const updatePersonalInfo = (
  data: PersonalInfo
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    await axios.post('/v1/user/personal-info/update', data);
    dispatch(updateUserPersonalInfoAction(true));
    dispatch(getPersonalInfo());
  } catch (error) {
    dispatch(updateUserPersonalInfoAction(false));
  }
};

export const updateProfileInfo = (
  data: ProfileInfo
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    await axios.post('/v1/user/profile/update', data);
    dispatch(updateUserProfileInfoAction(true));
  } catch (error) {
    dispatch(updateUserProfileInfoAction(false));
  }
};

export const getUserTransactionsHistory = (
  userId?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const response = await axios.get(
      `/v1/billing/turnover${userId ? `?user_id=${userId}` : ''}`
    );
    dispatch(getUserTransactionHistoryAction(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by loading payments history',
      })
    );
  }
};

export const getUserPaymentsHistory = (
  userId?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const response = await axios.get(
      `/v1/billing/payments${userId ? `?user_id=${userId}` : ''}`
    );
    dispatch(getUserPaymentsHistoryAction(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by loading payments history',
      })
    );
  }
};

export const getUserSubscriptions = (
  userId?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const response = await axios.get(
      `/v1/user/subscriptions${userId ? `?user_id=${userId}` : ''}`
    );
    dispatch(getUserSubscriptionsAction(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by loading user subscriptions',
      })
    );
  }
};