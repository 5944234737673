// @ts-nocheck
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Image, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/logo-white-bg.png';
import { attemptLogin } from 'src/store/authorization/thunks';
import { AppState } from 'src/store/store';

interface Props {
  attemptLogin: Function;
  history: any;
}

interface FormData {
  email: string;
  password: string;
}

export const LoginPage = (props: Props) => {
  const isLoggedIn = useSelector((state: AppState) => state.system.loggedIn);
  const error = useSelector((state: AppState) => state.system.error);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      props.history.push('/dashboard/main');
    }
  }, [isLoggedIn, props.history]);

  const handleSubmit = ({ email, password }: FormData) => {
    dispatch(attemptLogin(email, password));
  };

  return (
    <Col
      md={{ span: 12, offset: 6 }}
      lg={{ span: 12, offset: 6 }}
      xl={{ span: 8, offset: 8 }}
    >
      <Card
        cover={
          <Link to="/">
            <Image
              alt="VinCodes"
              src={logo}
              width="100%"
              preview={false}
              style={{ padding: '20px 25px 0' }}
            />
          </Link>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="email" label="Email">
                <Input
                  type="email"
                  prefix={<MailOutlined />}
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="password" label="Password">
                <Input
                  type="password"
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          {error ? (
            <>
              <Row gutter={8}>
                <Col xs={24}>
                  <Alert type="error" message={error} />
                </Col>
              </Row>
              <Row>&nbsp;</Row>
            </>
          ) : null}
          <Row gutter={8}>
            <Col xs={24}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Log In
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row gutter={8}>
        <Col xs={24}>
          <Link to="/forgot-password" style={{ float: 'left' }}>
            <small>Forgot password?</small>
          </Link>
          <Link to="/register" style={{ float: 'right' }}>
            <small>Create new account</small>
          </Link>
        </Col>
      </Row>
    </Col>
  );
};
