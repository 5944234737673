// @ts-nocheck
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LandingPage, PaymentHistoryPage } from 'src/pages';
import {
  ChangePasswordPage,
  ForgotPasswordPage,
  LoginPage,
  LogoutPage,
  SetPasswordPage,
} from 'src/pages/auth';
import { AdminDashboardPage } from 'src/pages/dashboard/AdminDashboard';
import { CodeHistoryPage } from 'src/pages/dashboard/CodeHistory/CodeHistoryPage';
import { KeyCodeRequestPage } from 'src/pages/dashboard/KeyCodeRequest/KeyCodeRequestPage';
import { MainPage } from 'src/pages/dashboard/MainPage';
import { PaymentMethodPage } from 'src/pages/dashboard/PaymentMethodPage';
import { ProfileTab } from 'src/pages/dashboard/Profile/ProfileTab';
import {
  CheckoutPage,
  SubscriptionPage,
} from 'src/pages/dashboard/Subscriptions';
import { RegistrationPage } from 'src/pages/register/RegistrationPage';
import { VerifyEmailPage } from 'src/pages/VerifyEmailPage';
import AdminRoute from './privateRouting/adminRoute';
import VerifiedRoute from './privateRouting/verifiedRoute';

const Router = () => (
  <>
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/register" component={RegistrationPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/set-password" component={SetPasswordPage} />
      <Route path="/verify-email/:token" component={VerifyEmailPage} />
      <VerifiedRoute
        fallBack="/"
        path="/dashboard/main"
        exact
        component={KeyCodeRequestPage}
      />
      <VerifiedRoute
        fallBack="/"
        path="/dashboard/key-request"
        exact
        component={KeyCodeRequestPage}
      />
      <VerifiedRoute
        fallBack="/"
        path="/dashboard/subscription"
        exact
        component={SubscriptionPage}
      />
      <VerifiedRoute
        fallBack="/"
        path="/checkout"
        exact
        component={CheckoutPage}
      />
      <VerifiedRoute
        fallBack="/"
        path="/dashboard/payment-methods"
        exact
        component={PaymentMethodPage}
      />
      <VerifiedRoute
        fallBack="/"
        path="/dashboard/payment-history"
        exact
        component={PaymentHistoryPage}
      />
      <VerifiedRoute
        fallBack="/"
        path="/dashboard/code-history"
        exact
        component={CodeHistoryPage}
      />
      <VerifiedRoute
        fallBack="/login"
        path="/profile/edit-profile"
        exact
        component={ProfileTab}
      />

      <VerifiedRoute
        fallBack="/login"
        path="/dashboard/my-subscription"
        exact
        component={MainPage}
      />
      
      <VerifiedRoute
        fallBack="/login"
        path="/profile/change-password"
        exact
        component={ChangePasswordPage}
      />
      <AdminRoute
        fallBack="/login"
        path="/administration/main"
        exact
        component={AdminDashboardPage}
      />
      <Route component={() => <div>not found</div>} />
    </Switch>
  </>
);

export default Router;
