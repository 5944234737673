// @ts-nocheck
import { Tabs } from 'antd';
import React from 'react';
import { EditPersonalPage } from './EditPersonalPage';
import { EditProfilePage } from './EditProfilePage';

const { TabPane } = Tabs;

export const ProfileTab = () => {
  return (
    <Tabs tabPosition="left" defaultActiveKey="1">
      <TabPane tab="Profile" key="1">
        <EditProfilePage />
      </TabPane>
      <TabPane tab="Personal Info" key="2">
        <EditPersonalPage />
      </TabPane>
    </Tabs>
  );
};
