export const phoneNumberValidator = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback();
  }

  const phone = value.replace(/\D/g, '');

  if (phone.length !== 10) {
    return callback('Please enter a valid phone number');
  }

  if (phone[0] === '1') {
    return callback('Please enter a valid phone number');
  }

  callback();
};
