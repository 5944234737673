import axios from 'axios';

export const setAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  axios.interceptors.request.use(
    (config) => {
      if (localStorage.authToken)
        config.headers.Authorization = `Bearer ${localStorage.authToken}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
};
