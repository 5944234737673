import { User } from 'src/store/user/interfaces';
import {
  LoginUserAction,
  RegisterUserSession,
  RegistrationActions,
  RegistrationSessionActions,
  SessionActions,
  SystemState,
} from './interfaces';
import * as types from './types';

export function updateUserLicenseDetails(details: RegistrationActions) {
  return {
    type: types.SET_LICENSE_INFO,
    payload: details,
  };
}

export function updateRegistrationSession(details: RegistrationSessionActions) {
  return {
    type: types.REGISTER_LOADING,
    payload: details,
  };
}

export function updateRegistrationSessionErrorAction(
  details: RegisterUserSession
) {
  return {
    type: types.REGISTER_FAILURE,
    payload: details,
  };
}

export function updateRegistrationSessionSuccessAction(
  details: RegisterUserSession
) {
  return {
    type: types.REGISTER_SUCCESS,
    payload: details,
  };
}

export function checkLicense(payload: boolean): RegistrationActions {
  return {
    type: types.CHECK_LICENSE,
    payload,
  };
}

export function setUserInfo(user: User): LoginUserAction {
  return {
    type: types.SET_USER,
    payload: user,
  };
}

export function loginSuccess(newSession: SystemState) {
  return {
    type: types.LOGIN_SUCCESS,
    payload: newSession,
  };
}

export function actionToken(token: string): SessionActions {
  return {
    type: types.TOKEN,
    token,
  };
}

export function actionLogout(): SessionActions {
  localStorage.removeItem('authToken');
  return {
    type: types.LOGOUT_ACTION,
  };
}

export function updateSession(newSession: SystemState) {
  return {
    type: types.UPDATE_SESSION,
    payload: newSession,
  };
}

export function loginRequest(newSession: SystemState) {
  return {
    type: types.LOGIN_REQUEST,
    payload: newSession,
  };
}

export function loginError(newSession: SystemState) {
  return {
    type: types.LOGIN_FAILURE,
    payload: newSession,
  };
}
