import { SubscriptionActions, SubscriptionReducer } from './interfaces';
import {
  ADD_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS,
} from './types';

const initialState: SubscriptionReducer = {
  allSubscriptions: [],
  deleteSubscription: false,
  addSubscription: false,
  changeSubscription: false,
  userSubscriptions: [],
};

const subscriptionReducer = (
  state = initialState,
  action: SubscriptionActions
): SubscriptionReducer => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS: {
      return {
        ...state,
        allSubscriptions: action.payload,
      };
    }
    case DELETE_SUBSCRIPTION: {
      return {
        ...state,
        deleteSubscription: action.payload,
      };
    }
    case ADD_SUBSCRIPTION: {
      return {
        ...state,
        addSubscription: action.payload,
      };
    }
    case CHANGE_SUBSCRIPTION: {
      return {
        ...state,
        changeSubscription: action.payload,
      };
    }
    case GET_USER_SUBSCRIPTIONS: {
      return {
        ...state,
        userSubscriptions: action.payload,
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
