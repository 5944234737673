// @ts-nocheck
import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import InputMask from 'react-input-mask';
import { STATE_OPTIONS } from 'src/constants';
import { phoneNumberValidator, zipCodeValidator } from 'src/validators';

const { Option } = Select;

export const PersonalInfoStep = () => {
  const stateOptions = STATE_OPTIONS.map(({ value, label }) => (
    <Option key={value} value={value}>
      {label}
    </Option>
  ));

  return (
    <>
      <Row gutter={8}>
        <Col xs={24}>
          <Form.Item
            label="Company Name"
            name="company_name"
            rules={[
              { required: true, message: 'Please enter your company name' },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              { required: true, message: 'Please enter your first name' },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please enter your email' }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Phone #"
            name="phone"
            rules={[
              { required: true, message: 'Please enter your phone number' },
              { validator: phoneNumberValidator },
            ]}
          >
            <InputMask mask="(999) 999-9999" maskPlaceholder="_">
              {() => <Input type="tel" />}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Form.Item
            name="address_line_1"
            label="Address Line 1"
            rules={[
              { required: true, message: 'Please enter your street address' },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Form.Item name="address_line_2" label="Address Line 2">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: 'Please enter your city' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="state"
            label="State"
            rules={[{ required: true, message: 'Please select your state' }]}
          >
            <Select placeholder="Select a state">{stateOptions}</Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="zip_code"
            label="Zip Code"
            rules={[
              { required: true, message: 'Please enter your zip code' },
              { validator: zipCodeValidator },
            ]}
          >
            <Input maxLength={5} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
