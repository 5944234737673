import {
  AdminActions,
  AdminConfig,
  PublicAdminConfig,
  GetUserForAdmin,
} from './interfaces';
import {
  GET_ADMIN_CONFIG,
  GET_PUBLIC_ADMIN_CONFIG,
  GET_USERS,
  SET_ADMIN_CONFIG,
} from './types';

export const getUsersForAdminAction = (
  payload: GetUserForAdmin[]
): AdminActions => ({
  type: GET_USERS,
  payload,
});

export function getConfig(payload: AdminConfig): AdminActions {
  return {
    type: GET_ADMIN_CONFIG,
    payload,
  };
}

export function getPublicConfig(payload: PublicAdminConfig): AdminActions {
  return {
    type: GET_PUBLIC_ADMIN_CONFIG,
    payload,
  };
}

export function setConfig(payload: AdminConfig): AdminActions {
  return {
    type: SET_ADMIN_CONFIG,
    payload,
  };
}
