// @ts-nocheck
import { Button, Image } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from 'src/assets/images/logo-white-bg.png';
import { AppState } from 'src/store/store';

export const LandingPage = () => {
  const isLoggedIn = useSelector((state: AppState) => state.system.loggedIn);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard/main');
    }
  }, [history, isLoggedIn]);

  return (
    <div
      style={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}
    >
      <Image alt="VinCodes" src={logo} width={600} preview={false} />
      <h3>Key cuts and programming codes for locksmiths using just a VIN.</h3>
      <div className="btn-wrapper mt-5">
        <Link to="/login">
          <Button>Login</Button>
        </Link>
        <Link to="/register">
          <Button color="github">Register</Button>
        </Link>
      </div>
    </div>
  );
};
