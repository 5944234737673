// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AppState } from 'src/store/store';
import { PrivateRouting } from '../interfaces';

const Verified = (props: PrivateRouting) => {
  const data = useSelector(({ system }: AppState) => system.session);

  return (
    <Route
      {...props}
      component={(newProps: any) => {
        const PageComponent: any = props.component;
        if (data) {
          return <PageComponent {...newProps} />;
        }
        return <Redirect to={props.fallBack} />;
      }}
    />
  );
};

export default Verified;
