import * as Interfaces from '../interfaces';
import * as types from '../types';

const initialState = {
  profileInfo: {
    id: '',
    phone: '',
    email: '',
    email_subscription: false,
    first_name: '',
    last_name: '',
    is_active: false,
    is_admin: false,
    company_name: '',
  },
  personalInfo: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    id: '',
    phone: '',
    state: '',
    user_id: '',
    zip_code: '',
  },
  updateProfile: false,
  updatePersonalInfo: false,
};

const ProfileReducer = (
  state = initialState,
  action: Interfaces.ProfileActionsInterfaces
): Interfaces.ProfileReducer => {
  switch (action.type) {
    case types.GET_PROFILE_INFO: {
      return {
        ...state,
        profileInfo: action.payload,
      };
    }
    case types.GET_PERSONAL_INFO: {
      return {
        ...state,
        personalInfo: action.payload,
      };
    }

    default:
      return state;
  }
};

export default ProfileReducer;
