// @ts-nocheck
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminConfig } from 'src/store/admin/interfaces';
import { getAdminConfig, setAdminConfig } from 'src/store/admin/thunks';
import { AppState } from 'src/store/store';

export const OtherConfigTable = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const config = useSelector((state: AppState) => state.adminReducer.config);

  const handleSubmit = (formData: AdminConfig) => {
    dispatch(setAdminConfig(formData));
  };

  useEffect(() => {
    dispatch(getAdminConfig());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(config);
  }, [form, config]);

  return (
    <Card title="Other Config">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="api_end_point" label="API Endpoint">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="stripe_secret_key" label="Stripe Secret Key">
              <TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="email_confirmation" valuePropName="checked" >
              <Checkbox style={{'height': "0px"}}>Email Confirmation</Checkbox >
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="license_check" valuePropName="checked">
              <Checkbox>Require License</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Card>
  );
};
