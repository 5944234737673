export const SET_USER = 'SET_USER';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const EMAIL_VERIFY_FAILURE = 'EMAIL_VERIFY_FAILURE';
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS';
export const TOKEN = 'TOKEN';
export const GET_PROFILE_INFO = 'GET PROFILE INFO';
export const GET_PERSONAL_INFO = 'GET PERSONAL INFO';
export const UPDATE_USER_PERSONAL_INFO = 'UPDATE USER PERSONAL INFO';
export const UPDATE_USER_PROFILE = 'UPDATE USER PROFILE';
export const LOGOUT_ACTION = 'LOGOUT ACTION';
export const GET_PAYMENTS_HISTORY = 'GET PAYMENTS HISTORY';
export const GET_TRASACTIONS_HISTORY = 'GET TRANSACTIONS HISTORY';
