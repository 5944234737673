// @ts-nocheck
import { Alert, Button, Modal, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/store';

interface Props {
  isShow: boolean;
  setIsShow: Function;
}

const { Column } = Table;

export const UserTransactions = (props: Props) => {
  const { isShow, setIsShow } = props;
  const userTransactions = useSelector(
    ({ userPayments }: AppState) => userPayments.userTransactionHistory
  );

  return (
    <Modal
      visible={isShow}
      onOk={() => setIsShow()}
      onCancel={() => setIsShow()}
      footer={[
        <Button htmlType="button" onClick={() => setIsShow()}>
          Close
        </Button>,
      ]}
      width={700}
    >
      <Table dataSource={userTransactions} pagination={false}>
        <Column title="Transaction Code" key="codeId"  render={(record) => {
          let value = ''
          if (record.code_history) {
            value = record.code_history.id
          }
          return value
        }}/>
        <Column title="Date" render={(text) => moment(text).format('hh:mm MM-DD-YYYY')} dataIndex="created_at" key="createdAt" />
        <Column title="Query Type" key="queryType" render={(record) => {
          let value = ''
          if (record.code_history) {
            value = record.code_history.query_type
          }
          return value
        }} />
        <Column title="VIN" key="VIN" 
          render={(record) => {
            let value = ''
            if (record.code_history) {
              value = record.code_history.vin_number
            }
            return value
          }}
        />
        <Column title="Error" key="error" 
          render={(record) => {
            let error = record.code_history.error
            error = (error || '').trim();
            if (!error.length) { return '' };
            if (error.length > 50) error = error.substring(0, 50) + '...';
            return <Alert type="error" message={error} />
          }}
        />
      </Table>
    </Modal>
  );
};