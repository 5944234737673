import jwtDecode from 'jwt-decode';
import {
  RegisterUserSession,
  RegisterUserState,
  RegistrationActions,
  RegistrationSessionActions,
  SessionActions,
  SystemState,
  TokenPayload,
} from './interfaces';
import {
  CHECK_LICENSE,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_ACTION,
  REGISTER_FAILURE,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  SET_LICENSE_INFO,
  TOKEN,
  UPDATE_SESSION,
} from './types';

const initialSessionState: SystemState = {
  loggedIn: false,
  session: '',
  username: '',
  isAdmin: false,
  userId: '',
  error: null,
};

const initialUserRegisterState: RegisterUserState = {
  licenseInfo: {
    license_number: '',
    license_required: false,
    drivers_license: '',
    proof_of_business: '',
  },
  checkLicense: false,
};

const initialRegistrationState: RegisterUserSession = {
  registering: false,
  registrationComplete: false,
  isSuccessful: false,
  error: null,
};

export function registerSessionReducer(
  state = initialRegistrationState,
  action: RegistrationSessionActions
): RegisterUserSession {
  switch (action.type) {
    case REGISTER_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REGISTER_LOADING: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export function registerReducer(
  state = initialUserRegisterState,
  action: RegistrationActions
): RegisterUserState {
  switch (action.type) {
    case SET_LICENSE_INFO: {
      return {
        ...state,
        licenseInfo: action.payload,
      };
    }
    case CHECK_LICENSE: {
      return {
        ...state,
        checkLicense: action.payload,
      };
    }
    default:
      return state;
  }
}

export function systemReducer(
  state = initialSessionState,
  action: SessionActions
): SystemState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGIN_REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TOKEN: {
      
      let payload: TokenPayload;
      
      try {
        payload = jwtDecode(action.token);
      } catch (error) {
        return state;
      }

      return {
        loggedIn: true,
        session: action.token,
        username: payload.user_name,
        isAdmin: payload.role === 'admin',
        userId: payload.user_id,
        error: null,
      };

    }
    case LOGOUT_ACTION: {
      return initialSessionState;
    }
    default:
      return state;
  }
}
