import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { showMessageAction } from '../showMessage/actions';
import { AppState } from '../store';
import * as actions from './actions';
import { UIConfiguration } from './interfaces';

let cachedConfig: UIConfiguration;

export const getUIConfig = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    if (!cachedConfig) {
      const payload = await axios.get('/v1/ui-config/');
      cachedConfig = payload.data.data;
    }
    dispatch(actions.getUIConfig(cachedConfig));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error getting configuration',
      })
    );
  }
};
