// @ts-nocheck
import { Alert, Card, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodeHistory } from 'src/store/codes/interfaces';
import { getCodeHistory } from 'src/store/codes/thunks';
import { AppState } from 'src/store/store';
import { getUIConfig } from 'src/store/uiconfig/thunks';
import { CodeHistoryModal } from './CodeHistoryModal';

const { Column } = Table;

export const CodeHistoryPage = () => {
  const dispatch = useDispatch();
  const codeHistory = useSelector(
    (state: AppState) => state.vincode.codeHistory
  );
  const [showModal, setShowModal] = useState(false);
  const [currentCode, setCurrentCode] = useState<CodeHistory | null>(null);

  const uiConfig = useSelector((state: AppState) => state.uiConfigReducer.codeRequestConfigs);

  const handleShowModal = () => setShowModal(!showModal);

  const handleClickRow = (code: CodeHistory) => {
    setShowModal(true);
    setCurrentCode(code);
  };

  useEffect(() => {
    dispatch(getCodeHistory());
    dispatch(getUIConfig());
  }, [dispatch]);

  const queryType = (record: CodeHistory): string => {
    let makeConfig = uiConfig.find((mc) => {
      return mc.id === record.make;
    });
    if (makeConfig) {
      let query = makeConfig.queries.find((q) => {
        return q.type === record.query_type;
      });
      if (query) {
        let ret = query.name;
        const adds = [];
        if (query.data0) {
          adds.push(`${query.data0.name}: ${record.data0}`);
        }
        if (query.data1) {
          adds.push(`${query.data1.name}: ${record.data1}`);
        }
        if (adds.length > 0) {
          return `${ret} (${adds.join(', ')})`;
        }
        return ret;
      }
    }
    return `record.queryType (${record.data0},${record.data1})`;
  }

  return (
    <>
      <Card title="Code History">
        <Table
          dataSource={codeHistory}
          pagination={false}
          onRow={(record: CodeHistory) => ({
            onClick: () => handleClickRow(record),
          })}
        >
          <Column title="Make" dataIndex="make" key="make" />
          <Column title="Model" dataIndex="model" key="model" />
          <Column title="VIN" dataIndex="vin_number" key="VIN" />
          <Column title="Query Type" dataIndex="query_type" key="queryType" render={(text, record: CodeHistory) => queryType(record)} />
          {/* <Column title="Work ID" dataIndex="work_id" key="workId" />
          <Column title="Tech ID" dataIndex="tech_id" key="techId" /> */}
          <Column title="Created On" dataIndex="created_at" key="created_at" />
          <Column title="Error" dataIndex="error" key="error"
            render={(text) => {
              text = (text || '').trim();
              if (!text.length) { return '' };
              if (text.length > 50) text = text.substring(0, 50) + '...';
              return <Alert type="error" message={text} />
            }}
          />
        </Table>
      </Card>
      <CodeHistoryModal
        visible={showModal}
        toggle={handleShowModal}
        codeInfo={currentCode}
      />
    </>
  );
};
