import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { showMessageAction } from '../showMessage/actions';
import { AppState } from '../store';
import * as actions from './actions';
import { AdminConfig } from './interfaces';

export const getAdminConfig = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('v1/admin/config');
    dispatch(actions.getConfig(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error fetching admin config',
      })
    );
  }
};

export const getPublicAdminConfig = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('v1/admin/public-config');
    dispatch(actions.getPublicConfig(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error fetching admin public config',
      })
    );
  }
};

export const setAdminConfig = (
  config: AdminConfig
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const response = await axios.post('/v1/admin/config', config);
    dispatch(actions.getConfig(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error updating admin config',
      })
    );
  }
};

export const getUsersForAdmin = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('/v1/admin/get-users');
    dispatch(actions.getUsersForAdminAction(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error fetching users',
      })
    );
  }
};
