// @ts-nocheck
import { Button, Card, Divider, Modal,Table, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store/store';
import {
  Subscription,
  UserSubscription,
} from 'src/store/subscriptions/interfaces';
import {
  getUserSubscriptions,
} from 'src/store/user/thunks';
import { User } from 'src/interfaces';

interface Props {
  isShow: boolean;
  setIsShow: Function;
  user: User;
}

const { Column } = Table;

export const UserSubscriptions = (props: Props) => {
  const { isShow, setIsShow, user } = props;

  const dispatch = useDispatch();

  const { allSubscriptions, userSubscriptions } = useSelector(
    (state: AppState) => state.subscriptions
  );

  const addUserSubscription = (sub: Subscription) => {
    fetch(`/api/v1/billing/subscriptions`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ subscription_id: sub.id, user_id: user.id }),
    })
      .then((_) => {
        dispatch(getUserSubscriptions(user.id));
      });
  };

  const deleteUserSubscription = (userSub: UserSubscription) => {
    console.log(user);
    fetch(`/api/v1/billing/subscription/delete`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_subscription_id: userSub.id, user_id: user.id }),
    })
      .then((_) => {
        dispatch(getUserSubscriptions(user.id));
      });
  };

  return (
    <Modal
      visible={isShow}
      onOk={() => setIsShow()}
      onCancel={() => setIsShow()}
      footer={[
        <Button htmlType="button" onClick={() => setIsShow()}>
          Close
        </Button>,
      ]}
      width={700}
    >
      <Card title="Token Packages">
        {userSubscriptions && (
          <>
            <h4>Current Subscriptions</h4>
            <Table dataSource={userSubscriptions} pagination={false}>
              <Column
                title="Description"
                dataIndex={['subscription', 'description']}
                key="description"
              />
              <Column
                title="Qty. Available"
                dataIndex="quantity_available"
                key="quantityAvailable"
              />
              <Column
                title="Qty. Used"
                dataIndex="quantity_used"
                key="quantityUsed"
              />
              <Column
                title=""
                key="renew"
                render={(_, userSub: UserSubscription) => (
                  <Popconfirm
                    title="Delete user subscription?"
                    onConfirm={() => deleteUserSubscription(userSub)}
                    okText="Yes" cancelText="No"
                  >
                    <Button danger type="primary">Delete</Button>
                  </Popconfirm>
                )}
              />
            </Table>
          </>
        )}
        <>
          {allSubscriptions.length && (
            <>
              <Divider />
              <h4>Available Subscriptions</h4>
              <Table dataSource={allSubscriptions} pagination={false}>
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column
                  title="Extra Desc."
                  dataIndex="extra"
                  key="extraDetails"
                />
                <Column
                  title="Cost"
                  dataIndex="cost"
                  key="cost"
                  render={(text) => `$${text.toFixed(2)}`}
                />
                <Column
                  title=""
                  key="purchase"
                  render={(_, sub: Subscription) => (
                    <Button type="primary" onClick={() => addUserSubscription(sub)}>Add</Button>
                  )}
                />
              </Table>
            </>
          )}
        </>
      </Card>
    </Modal>
  );
};
