// @ts-nocheck
import { Card, Divider, Row, Table, Button } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from 'src/store/store';
import { getUserSubscriptions } from 'src/store/subscriptions/thunks';

const { Column } = Table;

export const MainPage = () => {
  const dispatch = useDispatch();
  const userSubscriptions = useSelector(
    (state: AppState) => state.subscriptions.userSubscriptions
  );

  useEffect(() => {
    dispatch(getUserSubscriptions());
  }, [dispatch]);

  const currentBalanceData = [
    {
      quantityAvailable: userSubscriptions?.reduce((total, sub) => {
        return total + sub.quantity_available;
      }, 0),
      quantityUsed: userSubscriptions?.reduce((total, sub) => {
        return total + sub.quantity_used;
      }, 0),
    },
  ];

  return (
    <>
      <Card title="Your Membership Information">
        <div className="px-4">
          <Row className="justify-content-left">
            <h4>Your Subscriptions</h4>
          </Row>
          {!userSubscriptions && (
            <Row>
              <Link to="/dashboard/subscription">
                <Button type="primary">Add / Renew Token Package</Button>
              </Link>
            </Row>
          )}
          {userSubscriptions && (
            <Table dataSource={userSubscriptions} pagination={false}>
              <Column
                title="Description"
                dataIndex={['subscription', 'description']}
                key="description"
              />
              <Column
                title="Qty. Available"
                dataIndex="quantity_available"
                key="quantityAvailable"
              />
              <Column
                title="Qty. Used"
                dataIndex="quantity_used"
                key="quantityUsed"
              />
            </Table>
          )}
          <Divider />
          <Row className="justify-content-left">
            <h4>Current Balance</h4>
          </Row>
          {userSubscriptions && (
            <Table dataSource={currentBalanceData} pagination={false}>
              <Column
                title="Available"
                dataIndex="quantityAvailable"
                key="quantityAvailable1"
              />
              <Column
                title="Used"
                dataIndex="quantityUsed"
                key="quantityUsed1"
              />
            </Table>
          )}
          <Divider />
          <Link to="/dashboard/subscription">
            <Button type="primary">Manage Subscriptions</Button>
          </Link>
        </div>
      </Card>
    </>
  );
};
