// @ts-nocheck
import { Anchor, Button, Card, Divider, Table } from 'antd';
import React from 'react';
import { Subscription } from 'src/store/subscriptions/interfaces';

const { Column } = Table;

interface Props {
  subscriptions: Subscription[];
  onSubscriptionRowClick: Function;
  onSubscriptionAddClick: Function;
}

export const SubscriptionTable = (props: Props) => {
  const {
    subscriptions,
    onSubscriptionRowClick,
    onSubscriptionAddClick,
  } = props;
  return (
    <Card title="Subscriptions">
      <Table
        dataSource={subscriptions}
        pagination={false}
        onRow={(record) => ({
          onClick: () => onSubscriptionRowClick(record),
        })}
      >
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          render={(text, row: Subscription) => (
            <Anchor onClick={() => onSubscriptionRowClick(row)}>{text}</Anchor>
          )}
        />
        <Column
          title="Extra Desc."
          dataIndex="extra"
          key="extraDetails"
        />
        <Column
          title="Qty. Available"
          dataIndex="qty_available"
          key="quantityAvailable"
        />
        <Column title="Cost" dataIndex="cost" key="cost" />
      </Table>
      <Divider />
      <Button type="primary" onClick={() => onSubscriptionAddClick()}>
        Add Subscription
      </Button>
    </Card>
  );
};
