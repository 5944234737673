// @ts-nocheck
import {
  HomeOutlined,
  KeyOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/logo-white-bg.png';
import { AppState } from 'src/store/store';

const { SubMenu } = Menu;

export const TopNav = () => {
  const [current] = useState<string>('home');
  const isLoggedIn = useSelector((state: AppState) => state.system.loggedIn);
  const isAdmin = useSelector((state: AppState) => state.system.isAdmin);


  return (
    <>
      <Menu selectedKeys={[current]} mode="horizontal">
        <Link to="/">
          <img src={logo} className="logo" alt="VIN Codes" />
        </Link>
        {isLoggedIn && (
          <>
            <Menu.Item key="home" icon={<HomeOutlined />}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <SubMenu key="key-codes" title="Key / Codes" icon={<KeyOutlined />}>
              <Menu.Item key="/dashboard/key-request">
                <Link to="/dashboard/key-request">Key / Code Request</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/subscription">
                <Link to="/dashboard/subscription">
                  Add / Renew Token Package
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/code-history">
                <Link to="/dashboard/code-history">Code History</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="profile" title="Profile" icon={<UserOutlined />}>
              <Menu.Item key="/profile/change-password">
                <Link to="/profile/change-password">Change Password</Link>
              </Menu.Item>
              <Menu.Item key="/profile/edit-profile">
                <Link to="/profile/edit-profile">Edit Profile</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/payment-methods">
                <Link to="/dashboard/payment-methods">Payment Methods</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/my-subscription">
                <Link to="/dashboard/my-subscription">My Subscription</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/payment-history">
                <Link to="/dashboard/payment-history">Payment History</Link>
              </Menu.Item>
            </SubMenu>
            {isAdmin &&
              <Menu.Item key="admin" icon={<SettingOutlined />}>
                <Link to="/administration/main">Admin</Link>
              </Menu.Item>
            }
          </>
        )}
        <Menu.Item key="login" style={{ float: 'right' }}>
          {isLoggedIn ? (
            <Link to="/logout">Logout</Link>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </Menu.Item>
      </Menu>
    </>
  );
};
