import { AdminActions, AdminReducer } from './interfaces';
import {
  GET_ADMIN_CONFIG,
  GET_PUBLIC_ADMIN_CONFIG,
  GET_USERS,
} from './types';

const initialState: AdminReducer = {
  getUsers: [],
  config: {
    api_end_point: null,
    stripe_secret_key: null,
    email_confirmation: null,
    license_required: null,
  },
  publicConfig: {
    email_confirmation: null,
    license_required: null
  }
};

const adminReducer = (
  state = initialState,
  action: AdminActions
): AdminReducer => {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        getUsers: action.payload,
      };
    }
    case GET_ADMIN_CONFIG: {
      return {
        ...state,
        config: action.payload,
      };
    }
    case GET_PUBLIC_ADMIN_CONFIG: {
      return {
        ...state,
        publicConfig: action.payload,
      };
    }
    default:
      return state;
  }
};

export default adminReducer;
