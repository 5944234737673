import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { setAxios } from 'src/helpers';
import { actionToken } from './authorization/actions';
import rootReducer from './rootReducer';

setAxios();

const middleWareEnhancer =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) ||
  compose;

const middlewares = [thunkMiddleware];
const enhancer = middleWareEnhancer(applyMiddleware(...middlewares));

export type AppState = ReturnType<typeof rootReducer>;

function configureStore() {
  const store = createStore(rootReducer, enhancer);

  return store;
}

const store = configureStore();

// store.subscribe(() => console.log(store.getState()));

if (localStorage.authToken) store.dispatch(actionToken(localStorage.authToken));

export default store;
