import {
  UIConfigActions, UIConfiguration
} from './interfaces';
import * as types from './types';

export function getUIConfig(payload: UIConfiguration): UIConfigActions {
  return {
    type: types.GET_UI_CONFIGURATION,
    payload,
  };
}
