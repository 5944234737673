// @ts-nocheck
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const ForgotPasswordPage = () => {
  const [done, setDone] = useState(false);

  const handleSubmit = (formData: any) => {
    if (!formData.email) {
      return;
    }

    fetch(`/api/v1/user/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((j) => j.json())
      .then((data) => {
        setDone(true);
      });
  };

  if (done) {
    return (
      <Alert
        type="success"
        message="Password reset email has been sent.  Please check your email."
      />
    );
  }

  return (
    <Col
      md={{ span: 12, offset: 6 }}
      lg={{ span: 12, offset: 6 }}
      xl={{ span: 8, offset: 8 }}
    >
      <Card>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item name="email" label="Email">
                <Input type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row gutter={8}>
        <Col xs={24}>
          <Link to="/login" style={{ float: 'left' }}>
            <small>Log in</small>
          </Link>
          <Link to="/register" style={{ float: 'right' }}>
            <small>Create new account</small>
          </Link>
        </Col>
      </Row>
    </Col>
  );
};
