// @ts-nocheck
import { Button, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { User } from 'src/interfaces';
import { getUsersForAdmin } from 'src/store/admin/thunks';

interface Props {
  visible: boolean;
  toggle: Function;
  user: User;
}

export const UserAmendModal = (props: Props) => {

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { visible, toggle, user } = props;

  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (formData: any) => {
    // TODO: dispatch
    fetch('/api/v1/user/profile/update', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: user.id, ...formData }),
    }).then((data) => {
      toggle();
      dispatch(getUsersForAdmin());
    });
  };

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  const sendPasswordReset = () => {
    fetch(`/api/v1/user/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: user.email }),
    })
      .then((_) => {
        setEmailSent(true);
        toggle();
        dispatch(getUsersForAdmin());
      });
  };

  const deleteUser = () => {
    fetch(`/api/v1/admin/user`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: user.email }),
    })
      .then((_) => {
        toggle();
        dispatch(getUsersForAdmin());
      });
  };

  const unDeleteUser = () => {
    fetch(`/api/v1/admin/user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: user.email }),
    })
      .then((_) => {
        toggle();
        dispatch(getUsersForAdmin());
      });
  };

  return (
    <Modal
      title={'Edit User' + (user.deleted ? ' (Deleted)' : '')}
      visible={visible}
      onOk={() => toggle()}
      onCancel={() => toggle()}
      footer={
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <Popconfirm
              title={user.deleted ? 'Restore user?' : 'Delete user?'}
              onConfirm={user.deleted ? unDeleteUser : deleteUser}
              okText="Yes" cancelText="No"
            >
              <Button type="primary" danger htmlType="button">{user.deleted ? 'Restore' : 'Delete'}</Button>
            </Popconfirm>
          </Col>
          <Col span={12}>
            <Space>
              <Button htmlType="button" onClick={() => toggle()}>
                Close
              </Button>
              <Button form="user-form" type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Form
        id="user-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={8}>
          <Col xs={24} md={12} lg={12}>
            <Form.Item name="first_name" label="First Name">
              <Input />
            </Form.Item>
            <Form.Item name="last_name" label="Last Name">
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input type="email" />
            </Form.Item>
            <Form.Item name="new_password" label="New Password">
              <Row gutter={8}>
                <Col xs={12}>
                  <Input />
                </Col>
                <Col xs={12}>
                  <Button htmlType="button" onClick={() => sendPasswordReset()}>
                    {emailSent ? 'Reset Email Sent!' : 'Send Password Reset Email'}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
            <Form.Item name="is_admin" valuePropName="checked">
              <Checkbox>Administrator</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
};
