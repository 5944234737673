// @ts-nocheck
import { Card, Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store/store';
import { getUserPaymentsHistory } from 'src/store/user/thunks';

const { Column } = Table;

export const PaymentHistoryPage = () => {
  const dispatch = useDispatch();
  const paymentHistory = useSelector(
    (state: AppState) => state.userPayments.userPaymentsHistory || []
  );

  useEffect(() => {
    dispatch(getUserPaymentsHistory());
  }, [dispatch]);

  return (
    <>
      <Card title="Payment History">
        <Table dataSource={paymentHistory} pagination={false}>
          <Column
            title="Subscription"
            dataIndex={['subscription', 'description']}
            key="subscription"
          />
          <Column title="Status" dataIndex="status" key="status" />
          <Column title="Amount" dataIndex="amount" key="amount" />
          <Column
            title="Date"
            dataIndex="createdAt"
            key="createdAt"
            render={(text) => moment(text).format('hh:mm MM-DD-YYYY')}
          />
        </Table>
      </Card>
    </>
  );
};
