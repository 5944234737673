import * as interfaces from './interfaces';
import * as types from './types';

export function verifyError() {
  return {
    type: types.EMAIL_VERIFY_FAILURE,
  };
}

export function verifySuccess(token: string): interfaces.VerifyEmailActions {
  return {
    type: types.EMAIL_VERIFY_SUCCESS,
    payload: token,
  };
}

export function getProfileInfoAction(
  payload: interfaces.ProfileInfo
): interfaces.ProfileActionsInterfaces {
  return {
    type: types.GET_PROFILE_INFO,
    payload,
  };
}

export function getPersonalInfoAction(
  payload: interfaces.PersonalInfo
): interfaces.GetPersonalInfoAction {
  return {
    type: types.GET_PERSONAL_INFO,
    payload,
  };
}

export function updateUserPersonalInfoAction(
  payload: boolean
): interfaces.UpdateUserPersonalInfoAction {
  return {
    type: types.UPDATE_USER_PERSONAL_INFO,
    payload,
  };
}

export function updateUserProfileInfoAction(
  payload: boolean
): interfaces.UpdateUserProfileInfoAction {
  return {
    type: types.UPDATE_USER_PROFILE,
    payload,
  };
}

export function getUserPaymentsHistoryAction(
  payload: Array<interfaces.UserPaymentsHistory>
): interfaces.PaymentsActions {
  return {
    type: types.GET_PAYMENTS_HISTORY,
    payload,
  };
}

export function getUserTransactionHistoryAction(
  payload: Array<interfaces.UserTransactionHistory>
): interfaces.PaymentsActions {

  console.log(payload)

  return {
    type: types.GET_TRASACTIONS_HISTORY,
    payload,
  };
}
