import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { showMessageAction } from '../showMessage/actions';
import { AppState } from '../store';
import * as actions from './actions';

export const submitKeyRequestForm = (
  body: any
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const { data } = await axios.post('/v1/codes', body);
    const {
      data: {
        response: { vin, key_cut, pin_code_new, pin_code_old },
      },
    } = data;
    dispatch(
      actions.setKeyCodeDetails({
        vin_code: vin,
        key: key_cut,
        new_pin: pin_code_new,
        old_pin: pin_code_old,
      })
    );
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error changing key code info',
      })
    );
  }
};

export const getCodeHistory = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const payload = await axios.get('/v1/user/code-history');
    if (payload.data != null) {
      dispatch(actions.getCodeHistory(payload.data.data));
    } else {
      dispatch(actions.getCodeHistory([]));
    }
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error getting code history',
      })
    );
  }
};

export const getVincodeDetail = (
  vin: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const payload = await axios.get(`/v1/codes/vin/${vin}`);
    dispatch(actions.getVincodeDetail(payload.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by getting manufacturer list',
      })
    );
  }
};
