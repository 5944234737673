// @ts-nocheck
import { Checkbox, Col, Form, Input, Row } from 'antd';
import React from 'react';

export const LicenseInfoStep = () => {
  return (
    <>
      <Row gutter={8}>
        <Col xs={24}>
          <Form.Item name="license_required" valuePropName="checked">
            <Checkbox>License required by state?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Form.Item name="license_number" label="License #">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Form.Item name="drivers_license" label="Driver License #">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Form.Item name="proof_of_business" label="Proof of Business">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
