// @ts-nocheck
import { Button, Modal } from 'antd';
import React from 'react';

interface ModelProps {
  visible: boolean;
  toggle: any;
  status: string;
  message: string;
}

export const SubscriptionRequestModal = (props: ModelProps) => {
  const { visible, toggle } = props;

  return (
    <Modal
      title="Subscription Added Successfully"
      visible={visible}
      onOk={() => toggle()}
      onCancel={() => toggle()}
      footer={[
        <Button type="primary" onClick={() => toggle()}>
          OK
        </Button>,
      ]}
    >
      <p>The selected subscriptions have been added to your account</p>
    </Modal>
  );
};
