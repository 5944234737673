// @ts-nocheck
import { Col, Layout } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'src/App.css';
import 'src/assets/styles/globals.css';
import { TopNav } from 'src/components';
import Router from 'src/routing';

const { Header, Content, Footer } = Layout;

function App() {
  return (
      <BrowserRouter>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              backgroundColor: 'white',
            }}
          >
            <Col md={{ span: 18, offset: 3 }}>
              <TopNav />
            </Col>
          </Header>
          <Content className="site-layout" style={{ marginTop: 100 }}>
            <Col
              md={{ span: 22, offset: 1 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 18, offset: 3 }}
            >
              <Router />
            </Col>
          </Content>
          <Footer style={{ textAlign: 'center' }}>&copy;2021 VIN Codes</Footer>
        </Layout>
      </BrowserRouter>
  );
}

export default App;
