// @ts-nocheck
import { Button, Descriptions, Divider } from 'antd';
import React from 'react';
import { Registration } from 'src/interfaces';

type Props = {
  requireLicense: boolean;
  formData: Registration;
  setActiveStep: Function;
};

export function ReviewAndSubmitStep({ requireLicense, formData, setActiveStep }: Props) {
  const {
    company_name,
    first_name,
    last_name,
    email,
    phone,
    address_line_1,
    address_line_2,
    city,
    state,
    zip_code,
    license_number,
    license_required,
    drivers_license,
    proof_of_business,
  } = formData;

  return (
    <>
      <Descriptions
        title="Personal Info"
        size="small"
        bordered
        column={1}
        extra={
          <Button type="primary" onClick={() => setActiveStep(0)}>
            Edit
          </Button>
        }
      >
        <Descriptions.Item label="Company Name">
          {company_name}
        </Descriptions.Item>
        <Descriptions.Item label="First Name">{first_name}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{last_name}</Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          {email}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number">{phone}</Descriptions.Item>
        <Descriptions.Item label="Address Line 1">
          {address_line_1}
        </Descriptions.Item>
        <Descriptions.Item label="Address Line 2">
          {address_line_2}
        </Descriptions.Item>
        <Descriptions.Item label="City">{city}</Descriptions.Item>
        <Descriptions.Item label="State">{state}</Descriptions.Item>
        <Descriptions.Item label="Zip Code">{zip_code}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {requireLicense && (
        <Descriptions
          title="License Info"
          size="small"
          bordered
          column={1}
          extra={
            <Button type="primary" onClick={() => setActiveStep(1)}>
              Edit
          </Button>
          }
        >
          <Descriptions.Item label="License Number">
            {license_number}
          </Descriptions.Item>
          <Descriptions.Item label="License Required by State?">
            {license_required ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Driver License #">
            {drivers_license}
          </Descriptions.Item>
          <Descriptions.Item label="Proof of Business">
            {proof_of_business}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
}
