import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { showMessageAction } from '../showMessage/actions';
import { AppState } from '../store';
import {
  addSubscriptionsAction,
  deleteSubscriptionsAction,
  getSubscriptionsAction,
  getUserSubscriptionsAction,
  putSubscriptionsAction,
} from './actions';
import { Subscription } from './interfaces';

export const getSubscriptions = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('/v1/subscriptions');
    dispatch(getSubscriptionsAction(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by loading subscriptions',
      })
    );
  }
};

export const addSubscriptions = (
  data: Subscription
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  console.log(data)
  const body = {
    ...data,
    cost: +data.cost,
  };
  try {
    await axios.post('/v1/subscription', body);
    dispatch(getSubscriptions());
    dispatch(addSubscriptionsAction(true));
  } catch (error) {
    dispatch(addSubscriptionsAction(false));
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by adding subscription',
      })
    );
  }
};

export const changeSubscriptions = (
  id: string,
  data: Subscription
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const body = {
    ...data,
    cost: +data.cost,
    quantityAvailable: +data.qty_available,
  };
  try {
    await axios.put(`/v1/subscription/update/${id}`, body);
    dispatch(getSubscriptions());
    dispatch(putSubscriptionsAction(true));
  } catch (error) {
    dispatch(putSubscriptionsAction(false));
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by editing subscription',
      })
    );
  }
};

export const deleteSubscriptions = (
  id: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    await axios.delete(`/v1/subscription/delete/${id}`);
    dispatch(getSubscriptions());
    dispatch(deleteSubscriptionsAction(true));
  } catch (error) {
    dispatch(deleteSubscriptionsAction(false));
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by deleting subscription',
      })
    );
  }
};

export const getUserSubscriptions = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('/v1/user/subscriptions');
    dispatch(getUserSubscriptionsAction(response.data.data));
  } catch (error) {
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error by loading subscriptions',
      })
    );
  }
};
