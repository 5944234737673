// @ts-nocheck
import { Alert, Button, Card, Col, Divider, Form, Input, Radio, RadioChangeEvent, Row, Select, message } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodeHistory } from 'src/store/codes/interfaces';
import { AppState } from 'src/store/store';
import { ICodeRequestConfiguration, IMakeQuery } from 'src/store/uiconfig/interfaces';
import { getUIConfig } from 'src/store/uiconfig/thunks';
import { CodeHistoryModal } from '../CodeHistory/CodeHistoryModal';

const { Option } = Select;

interface FormData {
  vin: string;
  make: string;
  query: string;
  data0: string;
  data1: string;
  techId: string;
  locationId: string;
  workOrderId: string;
}

export const KeyCodeRequestPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoader] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [currentCode, setCurrentCode] = useState<CodeHistory | null>(null);

  const makes = useSelector(
    (state: AppState) => state.uiConfigReducer.codeRequestConfigs
  );

  const handleShowModal = () => setShowModal(!showModal);

  const [make, setMake] = useState<ICodeRequestConfiguration | undefined>();
  const [queries, setQueries] = useState<IMakeQuery[]>([]);
  const [query, setQuery] = useState<IMakeQuery | undefined>();

  dispatch(getUIConfig());

  const handleSubmit = (formData: FormData) => {
    setLoader(true);
    axios
      .post('/v1/codes', formData)
      .then(
        ({ data: { data } }) => {
          setCurrentCode(data)
          setShowModal(true)
        },
        (err) => { 
          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(err.response.data)
            const { error } = err.response.data
            if (err.response.status === 422) {
                message.error(error)
                return
            }

            if (error) {
              message.error(error)
            } else {
              message.error("Something went wrong. Please refresh the page and try again.")
            }


          } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
        }
      )
      .then(() => {
        setLoader(false);
      });

  };

  const onMakeChange = (makeId: string) => {
    const makeLookupKey = (makeId || '').toLowerCase();
    let makeConfig = makes.find((mc) => {
      return mc.id === makeLookupKey;
    }) || makes[0];

    setMake(makeConfig);
    setQueries(makeConfig.queries);
    setQuery(makeConfig.queries[0]);

    form.setFieldsValue({
      'query': makeConfig.queries[0].type,
    });

  };

  const onQueryChange = (ev: RadioChangeEvent) => {
    const queryType = ev.target.value || '';
    let found = queries.find((q) => {
      return q.type === queryType;
    });
    setQuery(found);
  };

  return (
    <>
      <Card title="Code Request Form">
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={8}>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="vin" label="VIN"
                  required
                  requiredMark
                  rules={[
                    {
                      required: true,
                      message: 'Please input a VIN',
                      len: 17
                    }
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
          <Row gutter={8}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item name="make" label="Make">
                <Select
                  placeholder="Select a make"
                  allowClear
                  onChange={onMakeChange}
                >
                  {makes.map((m) => (
                    <Option key={m.id} value={m.id}>
                      {m.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          {make && (
            <Row gutter={8}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item name="query" label="Query type">
                  <Radio.Group
                    onChange={onQueryChange}
                  >
                    {queries.map((q) => (
                      <Radio.Button key={q.type} value={q.type}>{q.name}</Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
          {query && !!query.description && (
            <>
              <Row gutter={8}>
                <Alert type="info" message={query.description} />
              </Row>
              <Row gutter={8}>&nbsp;</Row>
            </>
          )}
          {query?.data0 && query.data0.name !== "VIN" && (
            <>
              <Row gutter={8}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="data0" label={query.data0.name}
                    required
                    requiredMark
                    rules={[
                      {
                        required: true,
                        message: 'Please input: ' + query.data0.name,
                      }
                    ]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {query?.data1 && query.data1.name !== "VIN" && (
            <>
              <Row gutter={8}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="data1" label={query?.data1.name}
                    required
                    requiredMark
                    rules={[
                      {
                        required: true,
                        message: 'Please input: ' + query?.data1.name,
                      }
                    ]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {/* <Row gutter={8}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item name="techId" label="Tech ID" requiredMark='optional'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item name="locationId" label="Location ID" requiredMark='optional'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item name="workOrderId" label="Work Order #" requiredMark='optional'>
                <Input />
              </Form.Item>
            </Col>
          </Row> */}
          <Divider />
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Card>
      <CodeHistoryModal
        visible={showModal}
        toggle={handleShowModal}
        codeInfo={currentCode}
      />
    </>
  );
};
