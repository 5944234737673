// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { attemptLogout } from 'src/store/authorization/thunks';

export const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(attemptLogout());
  }, [dispatch]);

  return <Redirect to="/" />;
};
