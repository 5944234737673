// @ts-nocheck
import { Button, Card, Divider, Form, Input, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubscriptionRequestModal } from 'src/components/dashboard/modals';
import { AppState } from 'src/store/store';
import {
  Subscription,
  UserSubscription,
} from 'src/store/subscriptions/interfaces';
import {
  getSubscriptions,
  getUserSubscriptions,
} from 'src/store/subscriptions/thunks';

const { Column } = Table;

export const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { allSubscriptions, userSubscriptions } = useSelector(
    (state: AppState) => state.subscriptions
  );

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(getUserSubscriptions());
  }, [dispatch]);

  const subscriptionModalToggle = () => {
    form.setFieldsValue({
      subscriptionModalVisible: !form.getFieldValue('subscriptionModalVisible'),
    });
  };

  const [subscriptionModalVisible] = useState(false);
  const [requestMessage] = useState('');
  const [requestStatus] = useState('');

  return (
    <>
      <Card title="Add / Renew Token Package">
        {userSubscriptions && (
          <>
            <h4>Current Subscriptions</h4>
            <Table dataSource={userSubscriptions} pagination={false}>
              <Column
                title="Description"
                dataIndex={['subscription', 'description']}
                key="description"
              />
              <Column
                title="Qty. Available"
                dataIndex="quantity_available"
                key="quantityAvailable"
              />
              <Column
                title="Qty. Used"
                dataIndex="quantity_used"
                key="quantityUsed"
              />
              <Column
                title=""
                key="renew"
                render={(_, subscription: UserSubscription) => (
                  <Link
                    to={{
                      pathname: '/checkout',
                      state: { subscriptionId: subscription.subscription_id },
                    }}
                  >
                    <Button type="primary">Renew</Button>
                  </Link>
                )}
              />
            </Table>
          </>
        )}
        <>
          {allSubscriptions.length && (
            <>
              <Divider />
              <h4>Available Subscriptions</h4>
              <Table dataSource={allSubscriptions} pagination={false}>
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column
                  title="Extra Desc."
                  dataIndex="extra"
                  key="extra"
                />
                <Column
                  title="Cost"
                  dataIndex="cost"
                  key="cost"
                  render={(text) => `($${text.toFixed(2)})`}
                />
                <Column
                  title=""
                  key="purchase"
                  render={(_, subscription: Subscription) => (
                    <Link
                      to={{
                        pathname: '/checkout',
                        state: { subscriptionId: subscription.id },
                      }}
                    >
                      <Button type="primary">Purchase</Button>
                    </Link>
                  )}
                />
              </Table>
            </>
          )}
        </>
      </Card>
      {/* <Card className="card-profile shadow">
        <div className="px-4">
          <Row className="justify-content-left">
            <h4>Coupons</h4>
          </Row>
          <Row className="justify-content-left">
            <Form.Item name="couponCode" label="Coupon Code">
              <Input />
            </Form.Item>
          </Row>
        </div>
      </Card> */}
      <SubscriptionRequestModal
        toggle={subscriptionModalToggle}
        visible={subscriptionModalVisible}
        message={requestMessage}
        status={requestStatus}
      />
    </>
  );
};
