// @ts-nocheck
import { PaymentMethod } from '@stripe/stripe-js';
import { Button, Card, Col, Divider, Form, message, Row, Select } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subscription } from 'src/store/subscriptions/interfaces';
import { AddCardModal } from 'src/components/dashboard/modals';

const { Option } = Select;

interface FormData {
  subscription_id: string;
  payment_method_id: string;
}

export const CheckoutPage = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const { subscriptionId } = location.state;
  const initialValues = { subscriptionId };
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [total, setTotal] = useState<number | null>();
  const [addCardModalVisible, setAddCardModalVisible] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (subscriptionId) {
      const subscription = subscriptions.find((s) => s.id === subscriptionId);
      setTotal(subscription?.cost);
    }
  }, [subscriptions, subscriptionId]);

  const handleSubmit = (formData: FormData) => {
    setLoading(true);
    axios.post('/v1/billing/payment', formData).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        message.success('Purchase Successful!');
        history.push('/dashboard/my-subscription');
      } else {
        message.error('Purchase Failed!');
      }
      
    });
  };

  const getPaymentMethods = useCallback(() => {
    return axios
      .get('/v1/user/payment-methods')
      .then(({ data: { data } }) => data)
      .then(setPaymentMethods);
  }, []);

  const getSubscriptions = useCallback(() => {
    return axios
      .get('/v1/subscriptions')
      .then(({ data: { data } }) => data)
      .then(setSubscriptions);
  }, []);

  useEffect(() => {
    getPaymentMethods();
    getSubscriptions();
  }, [getPaymentMethods, getSubscriptions]);

  const addCardModalToggle = () => {
    setAddCardModalVisible(!addCardModalVisible);
    getPaymentMethods();
  };

  const subscriptionOptions = subscriptions.map((subscription) => (
    <Option key={subscription.id} value={subscription.id}>
      {subscription.description}
    </Option>
  ));
  const paymentMethodOptions = paymentMethods.map((paymentMethod) => (
    <Option key={paymentMethod.id} value={paymentMethod.id}>
      {paymentMethod.card?.brand} ending in {paymentMethod.card?.last4}
    </Option>
  ));

  const onSubscriptionChange = (subscriptionId: string) => {
    const subscription = subscriptions.find((s) => s.id === subscriptionId);
    setTotal(subscription?.cost);
  };

  return (
    <Col
      md={{ span: 12, offset: 6 }}
      lg={{ span: 12, offset: 6 }}
      xl={{ span: 8, offset: 8 }}
    >
      <Card title="Checkout">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item
                name="subscription_id"
                label="Subscription"
                rules={[
                  { required: true, message: 'Please select a subscription' },
                ]}
              >
                <Select
                  placeholder="Select a subscription"
                  allowClear
                  onChange={onSubscriptionChange}
                >
                  {subscriptionOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              {paymentMethodOptions.length > 0 && (
                <Form.Item
                  name="payment_method_id"
                  label="Payment Method"
                  rules={[
                    { required: true, message: 'Please select a payment method' },
                  ]}
                >
                  <Select placeholder="Select a payment method" allowClear>
                    {paymentMethodOptions}
                  </Select>
                </Form.Item>
              )}
              {paymentMethodOptions.length === 0 && (
                <div>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={(e) => addCardModalToggle()}
                  >
                    <span className="btn-inner--text">New Card</span>
                  </Button>
                  <AddCardModal toggle={addCardModalToggle} visible={addCardModalVisible} />
                </div>
              )}
            </Col>
          </Row>
          <Divider />
          <Col xs={24}>
            <h2>
              Total: <span style={{ float: 'right' }}>$ {total}</span>
            </h2>
          </Col>
          <Divider />
          <Col xs={24}>
            <div style={{ float: 'right' }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Purchase Subscription
              </Button>
            </div>
          </Col>
        </Form>
      </Card>
    </Col>
  );
};
