// @ts-nocheck
import { Button, Card, Table, Space } from 'antd';
import { useSelector } from 'react-redux';
import { GetUserForAdmin } from 'src/store/admin/interfaces';
import { AppState } from '../../../../store/store';

export interface Props {
  handleShowUserSubscriptionsHistory: Function;
  handleShowUserSubscriptions: Function;
  onUserEdit: Function;
}

const { Column } = Table;

export const UsersTable = (props: Props) => {

  const { handleShowUserSubscriptionsHistory, handleShowUserSubscriptions, onUserEdit } = props;

  const users = useSelector((state: AppState) => state.adminReducer.getUsers);

  return (
    <Card title="Users">
      <Table
        dataSource={users}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rowClassName={(record, index) => record.isDeleted ? 'table-row-deleted' : ''}
      >
        <Column title="First Name" dataIndex="first_name" key="firstName" />
        <Column title="Last Name" dataIndex="last_name" key="lastName" />
        <Column title="Email" dataIndex="email" key="email" />
        <Column
          title="Company Name"
          dataIndex="company_name"
          key="companyName"
        />
        <Column
          title="Active?"
          dataIndex="active"
          key="isActive"
          render={(text) => (text ? 'yes' : 'no')}
        />
        <Column
          title="Admin?"
          dataIndex="is_admin"
          key="isAdmin"
          render={(text) => (text ? 'yes' : 'no')}
        />
        <Column
          title=""
          key="details"
          render={(_, row: GetUserForAdmin) => (
            <Space>
              <Button onClick={() => handleShowUserSubscriptionsHistory(row.id)}>
                Transactions
              </Button>
              <Button onClick={() => handleShowUserSubscriptions(row)}>
                Tokens
              </Button>
              <Button onClick={() => onUserEdit(row)}>Edit</Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
};
