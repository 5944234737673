// @ts-nocheck
import { Alert, Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from 'src/store/store';

export const SetPasswordPage = () => {
  const history = useHistory();
  const token = useSelector((state: AppState) => state.verifyEmail.token);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [form] = Form.useForm();

  const handleSubmit = (formData: any) => {
    const { newPassword, confirmNewPassword } = formData;
    if (newPassword && newPassword === confirmNewPassword) {
      const body = {
        password: newPassword,
        token,
      };
      fetch('/api/v1/auth/set-password', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((j) => j.json())
        .then((data) => {
          if (data.data.ok === true) {
            history.push('/');
          } else {
            setMessage(null);
            setError('Error setting password');
          }
        });
    } else {
      setMessage(null);
      setError('Passwords do not match');
    }
  };

  if (!token) {
    return (
      <Alert
        type="error"
        message="Unable to set password.  Please verify your email first"
      />
    );
  }

  return (
    <Card title="Set Password">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="newPassword" label="New Password">
              <Input type="password" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="confirmNewPassword" label="Confirm New Password">
              <Input type="password" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        {message ? (
          <Row gutter={8}>
            <Alert type="success" message={message} />
          </Row>
        ) : null}
        {error ? (
          <Row gutter={8}>
            <Alert type="error" message={error} />
          </Row>
        ) : null}
        <Divider />
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <div style={{ float: 'right' }}>
              <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                Set Password
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
