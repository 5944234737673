import {
  CodeHistory,
  KeyCodeInfo,
  VinCodeActions,
  VinCodeDetail,
} from './interfaces';
import * as types from './types';

export function clearKeyCodeDetails(): VinCodeActions {
  return {
    type: types.CLEAR_KEYCODE_INFO,
  };
}

export function setKeyCodeDetails(payload: KeyCodeInfo): VinCodeActions {
  return {
    type: types.SET_KEYCODE_INFO,
    payload,
  };
}

export function getCodeHistory(payload: CodeHistory[]): VinCodeActions {
  return {
    type: types.GET_CODE_HISTORY,
    payload,
  };
}

export function getVincodeDetail(payload: VinCodeDetail): VinCodeActions {
  return {
    type: types.GET_VINCODE_DETAIL,
    payload,
  };
}
