// @ts-nocheck
import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store/store';
import { getPersonalInfo, updatePersonalInfo } from 'src/store/user/thunks';
import { phoneNumberValidator } from 'src/validators';

export const EditPersonalPage = () => {
  const dispatch = useDispatch();
  const personalInfo = useSelector(
    (state: AppState) => state.profile.personalInfo
  );
  const [form] = Form.useForm();

  const handleSubmit = (formData: any) => {
    dispatch(updatePersonalInfo(formData));
  };

  useEffect(() => {
    dispatch(getPersonalInfo());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(personalInfo);
  }, [form, personalInfo]);

  return (
    <Card title="Edit Personal Info">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: 'Please enter your phone number' },
                { validator: phoneNumberValidator },
              ]}
            >
              <InputMask mask="(999) 999-9999" maskPlaceholder="_">
                {() => <Input type="tel" />}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item
              name="address_line_1"
              label="Address Line 1"
              rules={[
                { required: true, message: 'Please enter your street address' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="address_line_2" label="Address Line 2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: 'Please enter your city' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: 'Please enter your state' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item
              name="zip_code"
              label="Zip Code"
              rules={[
                { required: true, message: 'Please enter your zip code' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Col xs={24}>
          <div style={{ float: 'right' }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Col>
      </Form>
    </Card>
  );
};
