import { LoginUserAction } from '../../authorization/interfaces';
import { User, VerifyEmailActions, VerifyEmailState } from '../interfaces';
import * as types from '../types';

const initialUserState: User = {
  username: '',
  first_name: '',
  last_name: '',
  company_name: '',
  email: '',
  token: '',
};

const initialVerifyEmailState: VerifyEmailState = {
  success: false,
  error: false,
  token: null,
};

export function userReducer(
  state = initialUserState,
  action: LoginUserAction
): User {
  switch (action.type) {
    case types.SET_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export function verifyEmailReducer(
  state = initialVerifyEmailState,
  action: VerifyEmailActions
): VerifyEmailState {
  switch (action.type) {
    case types.EMAIL_VERIFY_SUCCESS: {
      return {
        success: true,
        error: false,
        token: action.payload,
      };
    }
    case types.EMAIL_VERIFY_FAILURE: {
      return {
        success: false,
        error: true,
        token: null,
      };
    }
    default:
      return state;
  }
}
