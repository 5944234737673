import {
  Subscription,
  SubscriptionActions,
  UserSubscription,
} from './interfaces';
import {
  ADD_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS,
} from './types';

export const getSubscriptionsAction = (
  payload: Subscription[]
): SubscriptionActions => ({
  type: GET_SUBSCRIPTIONS,
  payload,
});

export const addSubscriptionsAction = (
  payload: boolean
): SubscriptionActions => ({
  type: ADD_SUBSCRIPTION,
  payload,
});

export const deleteSubscriptionsAction = (
  payload: boolean
): SubscriptionActions => ({
  type: DELETE_SUBSCRIPTION,
  payload,
});

export const putSubscriptionsAction = (
  payload: boolean
): SubscriptionActions => ({
  type: CHANGE_SUBSCRIPTION,
  payload,
});

export const getUserSubscriptionsAction = (
  payload: UserSubscription[]
): SubscriptionActions => ({
  type: GET_USER_SUBSCRIPTIONS,
  payload,
});
