// @ts-nocheck
import { Button, Card, Col, Descriptions, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { AddCardModal } from 'src/components/dashboard/modals';

interface PaymentMethod {
  id: string;
  card?: {
    brand: string;
    last4: string;
    exp_month: string;
    exp_year: string;
  };
}

export const PaymentMethodPage = () => {
  const [addCardModalVisible, setAddCardModalVisible] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = () => {
    fetch('/api/v1/user/payment-methods', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.authToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then(({ data }) => setPaymentMethods(data));
  };

  const addCardModalToggle = () => {
    setAddCardModalVisible(!addCardModalVisible);
    getPaymentMethods();
  };

  const deletePaymentMethod = (paymentMethod: PaymentMethod) => {
    fetch(
      `${window.location.protocol}//${window.location.host}/api/v1/user/payment-method/delete/${paymentMethod.id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.authToken}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((r) => r.json())
      .then(() => {
        getPaymentMethods();
      });
  };

  return (
    <Card title="Payment Methods">
      {paymentMethods.map((paymentMethod, i) => (
        <Col xs={24} md={24} key={i}>
          <Descriptions size="small" bordered column={1}>
            <Descriptions.Item label="Type">
              {paymentMethod.card?.brand}
            </Descriptions.Item>
            <Descriptions.Item label="Number">
              **** **** **** {paymentMethod.card?.last4}
            </Descriptions.Item>
            <Descriptions.Item label="Expiration">
              {paymentMethod.card?.exp_month +
                ' / ' +
                paymentMethod.card?.exp_year}
                <Button
                  type="primary"
                  danger
                  htmlType="button"
                  onClick={(e) => deletePaymentMethod(paymentMethod)}
                  className='pull-right'
                >
                  Delete
                </Button>
            </Descriptions.Item>
          </Descriptions>
          
        </Col>
      ))}
      <Divider />
      <Button
        type="primary"
        htmlType="button"
        onClick={(e) => addCardModalToggle()}
      >
        <span className="btn-inner--text">New Card</span>
      </Button>
      <AddCardModal toggle={addCardModalToggle} visible={addCardModalVisible} />
    </Card>
  );
};
