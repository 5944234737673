import { VinCodeActions, VincodeReducerState } from './interfaces';
import {
  CLEAR_KEYCODE_INFO,
  GET_CODE_HISTORY,
  GET_VINCODE_DETAIL,
  SET_KEYCODE_INFO,
} from './types';

const initialKeyCodeState: VincodeReducerState = {
  codeInfo: {
    vin_code: '',
    key: '',
    new_pin: '',
    old_pin: '',
  },
  vincodeDetail: {
    vin: '',
    wmi: '',
    vds: '',
    vis: '',
    year: '',
    region: '',
    manufacturer: '',
    assembly_plant: '',
    serial_number: '',
    make: '',
    model: '',
    vehicle_type: '',
  },
  codeHistory: [],
};

export function vincodeReducer(
  state = initialKeyCodeState,
  action: VinCodeActions
): VincodeReducerState {
  switch (action.type) {
    case CLEAR_KEYCODE_INFO: {
      return {
        ...state,
        codeInfo: {
          vin_code: '',
          key: '',
          new_pin: '',
          old_pin: '',
        },
      };
    }
    case SET_KEYCODE_INFO: {
      return {
        ...state,
        codeInfo: action.payload,
      };
    }
    case GET_CODE_HISTORY: {
      return {
        ...state,
        codeHistory: action.payload,
      };
    }
    case GET_VINCODE_DETAIL: {
      return {
        ...state,
        vincodeDetail: action.payload,
      };
    }

    default:
      return state;
  }
}
