// @ts-nocheck
import { Alert, Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

interface FormData {
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}

export const ChangePasswordPage = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [form] = Form.useForm();

  const handleSubmit = (formData: FormData) => {
    const { old_password, new_password, confirm_new_password } = formData;
    if (old_password && new_password && new_password === confirm_new_password) {
      const body = {
        old_password,
        new_password,
      };
      axios.post('/v1/user/change-password', body).then(({ data }) => {
        if (data.data.ok === true) {
          setMessage('Password Changed');
          setError(null);
        } else {
          setMessage(null);
          setError('Current password incorrect');
        }
      });
    } else {
      setMessage(null);
      setError('Password confirmation incorrect');
    }
  };

  return (
    <Card title="Change Password">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="old_password" label="Current Password">
              <Input type="password" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="new_password" label="New Password">
              <Input type="password" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="confirm_new_password" label="Confirm New Password">
              <Input type="password" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        {message ? (
          <Row gutter={8}>
            <Alert type="success" message={message} />
          </Row>
        ) : null}
        {error ? (
          <Row gutter={8}>
            <Alert type="error" message={error} />
          </Row>
        ) : null}
        <Divider />
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <div style={{ float: 'right' }}>
              <Button type="primary" htmlType="submit">
                Change Password
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
