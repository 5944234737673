import * as interfaces from '../interfaces';
import * as types from '../types';

const initialState: interfaces.PaymentsReducer = {
  userPaymentsHistory: null,
  userTransactionHistory: [],
};

export default function paymentsReducer(
  state = initialState,
  action: interfaces.PaymentsActions
): interfaces.PaymentsReducer {
  switch (action.type) {
    case types.GET_PAYMENTS_HISTORY: {
      return {
        ...state,
        userPaymentsHistory: action.payload,
      };
    }
    case types.GET_TRASACTIONS_HISTORY: {
      return {
        ...state,
        userTransactionHistory: action.payload,
      };
    }
    default:
      return state;
  }
}
