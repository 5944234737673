// @ts-nocheck
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'src/interfaces';
import { getUsersForAdmin } from 'src/store/admin/thunks';
import { AppState } from 'src/store/store';
import { Subscription } from 'src/store/subscriptions/interfaces';
import { getSubscriptions } from 'src/store/subscriptions/thunks';
import { getUserSubscriptions, getUserTransactionsHistory } from 'src/store/user/thunks';
import {
  OtherConfigTable,
  SubscriptionAmendModal,
  SubscriptionTable,
  UserAmendModal,
  UsersTable,
  UserTransactions,
  UserSubscriptions
} from './components';

const { TabPane } = Tabs;

interface State {
  apiEndpoint: string;
  emailConfirmation: boolean;
  licenseRequired: boolean;
  subscriptionAmendModalVisible: boolean;
  selectedSubscription: Subscription;
  subscriptionAmend: boolean;
  stripeSecretKey: string;
  userAmendModalVisible: boolean;
  isSubscriptionAdd: boolean;
  selectedUser: User;
  isShowUserTransactionsHistory: boolean;
  isShowUserSubscriptions: boolean;
  activeTab: string;
}

export const AdminDashboardPage = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState<State>({
    apiEndpoint: '',
    emailConfirmation: false,
    licenseRequired: false,
    subscriptionAmendModalVisible: false,
    selectedSubscription: {
      cost: 0,
      description: '',
      extra: '',
      id: '',
      qty_available: 0,
    },
    selectedUser: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      is_active: false,
      is_admin: false,
      company_name: '',
      deleted: false
    },
    subscriptionAmend: false,
    isSubscriptionAdd: false,
    stripeSecretKey: '',
    userAmendModalVisible: false,
    isShowUserTransactionsHistory: false,
    isShowUserSubscriptions: false,
    activeTab: '1',
  });

  const subscriptions = useSelector(
    (state: AppState) => state.subscriptions.allSubscriptions
  );

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(getUsersForAdmin());
  }, [dispatch]);

  const toggleSubscriptionAmendModal = () => {
    if (state.subscriptionAmendModalVisible) {
      setState((prevState) => ({
        ...prevState,
        subscriptionAmendModalVisible: !state.subscriptionAmendModalVisible,
        isSubscriptionAdd: false,
        subscriptionAmend: false,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      subscriptionAmendModalVisible: !state.subscriptionAmendModalVisible,
    }));
  };

  const toggleUserAmendModal = () => {
    setState((prevState) => ({
      ...prevState,
      userAmendModalVisible: !state.userAmendModalVisible,
    }));
  };

  const toggleUserSubscriptionsModal = () => {
    setState((prevState) => ({
      ...prevState,
      isShowUserSubscriptions: !state.isShowUserSubscriptions,
    }));
  };

  const handleShowUserSubscriptionsHistory = (id: string) => {
    if (!state.isShowUserTransactionsHistory) {
      dispatch(getUserTransactionsHistory(id));
    }
    setState((prevState) => ({
      ...prevState,
      isShowUserTransactionsHistory: !state.isShowUserTransactionsHistory,
    }));
  };

  const handleShowUserSubscriptions = (user: User) => {
    if (!state.isShowUserSubscriptions) {
      dispatch(getUserSubscriptions(user.id));
      setState((prevState) => ({
        ...prevState,
        selectedUser: user
      }));
    }
    toggleUserSubscriptionsModal()
  };

  const onSubscriptionRowClick = (sub: Subscription) => {
    setState((prevState) => ({
      ...prevState,
      selectedSubscription: sub,
      subscriptionAmend: true,
    }));

    toggleSubscriptionAmendModal();
  };

  const onUserEdit = (user: User) => {
    setState((prevState) => ({
      ...prevState,
      selectedUser: user,
    }));
    toggleUserAmendModal();
  };

  const onSubscriptionAddClick = () => {
    setState((prevState) => ({
      ...prevState,
      selectedSubscription: {
        cost: 0,
        description: '',
        extra: '',
        id: '',
        qty_available: 0,
      },
      isSubscriptionAdd: true,
    }));

    toggleSubscriptionAmendModal();
  };

  return (
    <>
      <Tabs tabPosition="left" defaultActiveKey="1">
        <TabPane tab="Users" key="1">
          <UsersTable
            handleShowUserSubscriptionsHistory={
              handleShowUserSubscriptionsHistory
            }
            handleShowUserSubscriptions={
              handleShowUserSubscriptions
            }
            onUserEdit={onUserEdit}
          />
        </TabPane>
        <TabPane tab="Subscriptions" key="2">
          <SubscriptionTable
            subscriptions={subscriptions}
            onSubscriptionRowClick={onSubscriptionRowClick}
            onSubscriptionAddClick={onSubscriptionAddClick}
          />
        </TabPane>
        <TabPane tab="Other Config" key="3">
          <OtherConfigTable />
        </TabPane>
      </Tabs>
      <SubscriptionAmendModal
        subscription={state.selectedSubscription}
        visible={state.subscriptionAmendModalVisible}
        toggle={toggleSubscriptionAmendModal}
      />
      <UserAmendModal
        visible={state.userAmendModalVisible}
        toggle={toggleUserAmendModal}
        user={state.selectedUser}
      />
      <UserTransactions
        isShow={state.isShowUserTransactionsHistory}
        setIsShow={handleShowUserSubscriptionsHistory}
      />
      <UserSubscriptions
        isShow={state.isShowUserSubscriptions}
        setIsShow={handleShowUserSubscriptions}
        user={state.selectedUser}
      />
    </>
  );
};
