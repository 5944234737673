import { combineReducers } from 'redux';
import AdminReducer from './admin/reducer';
import {
  registerReducer,
  registerSessionReducer,
  systemReducer,
} from './authorization/reducer';
import { vincodeReducer } from './codes/reducer';
import showMessageReducer from './showMessage/reducer';
import subscriptionReducer from './subscriptions/reducer';
import paymentsReducer from './user/reducer/paymentsReducer';
import ProfileReducer from './user/reducer/profileReducer';
import { userReducer, verifyEmailReducer } from './user/reducer/reducer';
import { uiConfigReducer } from './uiconfig/reducer';

export default combineReducers({
  user: userReducer,
  profile: ProfileReducer,
  verifyEmail: verifyEmailReducer,
  subscriptions: subscriptionReducer,
  system: systemReducer,
  register: registerReducer,
  registerSession: registerSessionReducer,
  vincode: vincodeReducer,
  showMessage: showMessageReducer,
  userPayments: paymentsReducer,
  adminReducer: AdminReducer,
  uiConfigReducer: uiConfigReducer,
});
