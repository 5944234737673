export const zipCodeValidator = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback();
  }

  const zipCode = value.replace(/\D/g, '');

  if (zipCode.length !== 5) {
    return callback('Please enter a valid zip code');
  }

  callback();
};
