// @ts-nocheck
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store/store';
import { getProfileInfo, updateProfileInfo } from 'src/store/user/thunks';
import { ProfileInfo } from 'src/store/user/interfaces';

export const EditProfilePage = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: AppState) => state.system.isAdmin);
  const profileInfo = useSelector(
    (state: AppState) => state.profile.profileInfo
  );
  const [form] = Form.useForm();

  const handleSubmit = (formData: ProfileInfo) => {
    dispatch(updateProfileInfo(formData));
  };

  useEffect(() => {
    dispatch(getProfileInfo());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(profileInfo);
  }, [form, profileInfo]);

  return (
    <Card title="Edit Profile">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="company_name" label="Company Name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="first_name" label="First Name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="last_name" label="Last Name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="email" label="Email Address">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item name="email_subscription" valuePropName="checked">
              <Checkbox>Subscribe to newsletter</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {isAdmin ? (
          <>
            <Row gutter={8}>
              <Col xs={24}>
                <Form.Item name="active" valuePropName="checked">
                  <Checkbox>Active</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={24}>
                <Form.Item name="is_admin" valuePropName="checked">
                  <Checkbox>Admin</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        <Divider />
        <Col xs={24}>
          <div style={{ float: 'right' }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Col>
      </Form>
    </Card>
  );
};
