import { UIConfigActions, UIConfigReducerState } from './interfaces';
import {
  GET_UI_CONFIGURATION
} from './types';

const initialConfig: UIConfigReducerState = {
  codeRequestConfigs: []
};

export function uiConfigReducer(
  state = initialConfig,
  action: UIConfigActions
): UIConfigReducerState {
  switch (action.type) {
    case GET_UI_CONFIGURATION: {
      return {
        ...state,
        codeRequestConfigs: action.payload.codeRequests
      };
    }
    default:
      return state;
  }
}
