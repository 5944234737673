// @ts-nocheck
import { Alert, Button, Card, Col, Divider, Form, Row, Steps } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Registration } from 'src/interfaces';
import {
  LicenseInfoStep,
  PersonalInfoStep,
  ReviewAndSubmitStep,
} from 'src/pages/register/components';
import { attemptRegisterUser } from 'src/store/authorization/thunks';
import { getPublicAdminConfig } from 'src/store/admin/thunks';
import { AppState } from 'src/store/store';

const { Step } = Steps;

function getStepContent(
  requireLicense: boolean | null,
  stepIndex: number,
  formData: Registration,
  setActiveStep: Function
) {

  console.log("requireLicense", requireLicense)

  if (stepIndex === 0) {
    return <PersonalInfoStep />;
  }
  if (stepIndex === 1 && requireLicense) {
    return <LicenseInfoStep />;
  }

  return <ReviewAndSubmitStep
    requireLicense={!!requireLicense}
    formData={formData}
    setActiveStep={setActiveStep}
  />;
}

export const RegistrationPage = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<Registration>({
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip_code: '',
    license_number: '',
    license_required: true,
    drivers_license: '',
    proof_of_business: '',
  });

  const [form] = Form.useForm();

  const [activeStep, setActiveStep] = React.useState(0);

  const { error, isSuccessful } = useSelector(
    (state: AppState) => state.registerSession
  );
  const [steps, setSteps] = useState(['Personal Info', 'License Info', 'Register']);

  const publicConfig = useSelector((state: AppState) => state.adminReducer.publicConfig);

  useEffect(() => {
    dispatch(getPublicAdminConfig());
  }, [dispatch]);

  useEffect(() => {
    console.log(publicConfig);
    if (publicConfig.license_check === false) {
      setSteps(['Personal Info', 'Register']);
    } else if (publicConfig.license_check === true) {
      setSteps(['Personal Info', 'License Info', 'Register']);
    }
  }, [publicConfig]);

  const handleNext = () => {
    setFormData((prevState) => ({ ...prevState, ...form.getFieldsValue() }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setFormData((prevState) => ({ ...prevState, ...form.getFieldsValue() }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isFirstStep = () => {
    return activeStep === 0;
  }

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  }

  const onSubmit = async () => {
    if (isLastStep()) {
      dispatch(attemptRegisterUser(formData));
    } else {
      handleNext();
    }
  };

  if (isSuccessful) {
    return (
      <Alert
        type="success"
        message={publicConfig.email_confirmation
          ? "Registration complete.  Please check your email to finish the verification process."
          : "Registration complete."
        }
      />
    );
  }

  return (
    <Col
      md={{ span: 12, offset: 6 }}
      lg={{ span: 12, offset: 6 }}
      xl={{ span: 10, offset: 7 }}
    >
      <Card>
        <Form
          form={form}
          layout="vertical"
          initialValues={formData}
          onFinish={onSubmit}
        >
          <Row>
            <Col xs={0} md={24}>
              <Steps current={activeStep}>
                {steps.map((step, idx) => (
                  <Step key={idx} title={step} />
                ))}
              </Steps>
            </Col>
            <Divider />
            <Col xs={24}>
              <div>{getStepContent(publicConfig.license_check, activeStep, formData, setActiveStep)}</div>
              <Divider />
              <div style={{ float: 'left' }}>
                {!isFirstStep && (
                  <Button htmlType="button" onClick={handleBack}>
                    Back
                  </Button>
                )}
              </div>
              <div style={{ float: 'right' }}>
                {isLastStep() ? (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Next
                  </Button>
                )}
              </div>
              <Form.ErrorList errors={[error]} />
            </Col>
          </Row>
        </Form>
      </Card>
    </Col>
  );
};
