import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Registration } from 'src/interfaces';
import { showMessageAction } from '../showMessage/actions';
import { AppState } from '../store';
import * as actions from './actions';
import {
  actionLogout,
  actionToken,
  loginError,
  loginSuccess,
  setUserInfo,
  updateSession,
} from './actions';

export const checkLicense = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get('/v1/config/api-config?key=licensecheck');
    dispatch(actions.checkLicense(response.data));
  } catch (e) {
    console.log(e);
  }
};

export const attemptRegisterUser = (
  form: Registration
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    await axios.post('/v1/auth/register', form);
    dispatch(
      actions.updateRegistrationSessionSuccessAction({
        isSuccessful: true,
        registering: false,
        registrationComplete: true,
        error: null,
      })
    );
  } catch (err) {
    const error = axios.isAxiosError(err)
      ? err.response?.data.error ?? 'system error'
      : 'system error';
    dispatch(
      actions.updateRegistrationSessionErrorAction({
        isSuccessful: false,
        registering: false,
        registrationComplete: true,
        error,
      })
    );
    dispatch(
      showMessageAction({
        variant: 'danger',
        message: 'Error during registration',
      })
    );
  }
};

export const registerLicenseDetails = (
  license_number: string,
  license_required: boolean,
  drivers_license: string,
  proof_Of_business: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  // dispatch(actions.updateUserLicenseDetails({
  //   licenseNumber,
  //   noLicenseRequired,
  //   driversLicense,
  //   proofOfBusiness,
  // }));
};

export const attemptLogin = (
  email: string,
  password: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const body = { email, password };
  dispatch(
    updateSession({
      loggedIn: false,
      session: '',
      username: '',
      isAdmin: false,
      userId: '',
      error: null,
    })
  );
  try {
    const response = await axios.post('/v1/auth/login', body);
    dispatch(setUserInfo(response.data.data));
    dispatch(actionToken(response.data.data.token));
    localStorage.authToken = response.data.data.token;
  } catch (err) {
    const error = axios.isAxiosError(err)
      ? err.response?.data.error ?? 'system error'
      : 'system error';
    dispatch(
      loginError({
        loggedIn: false,
        session: '',
        username: '',
        isAdmin: false,
        userId: '',
        error,
      })
    );
  }
};

export const attemptLogout = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  await axios.post('/v1/auth/logout');
  dispatch(actionLogout());
  dispatch(
    loginError({
      loggedIn: false,
      session: '',
      username: '',
      userId: '',
      isAdmin: false,
      error: null,
    })
  );
};

// !!!
export const checkToken = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  fetch(
    `${window.location.protocol}//${window.location.host}/api/v1/user/check-token`
  )
    .then((r) => r.json())
    .then((data) => {
      if (data.status === 401) {
        dispatch(
          loginError({
            loggedIn: false,
            session: '',
            username: '',
            isAdmin: false,
            userId: '',
            error: 'unauthorized',
          })
        );
      } else {
        dispatch(
          loginSuccess({
            loggedIn: true,
            session: data.token,
            username: data.username,
            isAdmin: data.isAdmin,
            userId: '',
            error: null,
          })
        );
      }
    })
    .catch((e) => {
      console.log(e);
    });
};
